import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'gatsby-theme-husky/src/layout/Layout/Layout';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import PageSchema from 'gatsby-theme-husky/src/common/PageSchema';
import SignUpBanner from 'gatsby-theme-husky/src/components/SignUpBanner';
import Banner from 'gatsby-theme-husky/src/common/Banner';
import ArticleList from 'gatsby-theme-husky/src/components/ArticleList';
import { toBoolean } from 'gatsby-theme-husky/src/utils/stringUtils/stringUtils';
import VideosYoutube from '../../components/VideosYoutube';

import 'gatsby-theme-husky/src/templates/TipsAndTricksPage/tipsAndTricks.scss';

const TipsAndTricksPage: FC<TipsAndTricksTypes.TipsAndTricksProps> = ({
  data: {
    allUmbracoTipsAndTricks: { nodes: AllTipsAndTricksPage },
    allUmbracoArticles: { nodes: umbracoArticlesNodes },
    allUmbracoTipsForYou: { nodes: umbracoTipsForYouNodes },
    allUmbracoVideoYoutube: { nodes: umbracoVideosYoutube },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    articlesMainBanner: { content, bannerVariant, imageAsBackground, imageAsBackgroundAlt },
    articleListSettings,
    tipsAndTricksVideoBanner: {
      bannerTitle,
      content: tipsAndTricksBannerContent,
      bannerVariant: tipsAndTricksBannerVariant,
      imageAsBackgroundAlt: tipsAndTricksBannerimageAsBackgroundAlt,
      withWrapper,
      withVideo,
    },
    videosTitle,
    videosSubtitle,
  } = AllTipsAndTricksPage[0];

  const { url: tipsForYouUrl } = umbracoTipsForYouNodes[0] || '/';

  return (
    <Layout langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Banner
        {...{
          content,
          bannerVariant,
          imageAsBackground,
          imageAsBackgroundAlt,
        }}
      />
      {articleListSettings ? (
        <ArticleList
          {...{ articleListSettings, umbracoArticlesNodes, loadAllArticlesLink: tipsForYouUrl }}
        />
      ) : (
        <VideosYoutube videoList={umbracoVideosYoutube} {...{ videosTitle, videosSubtitle }} />
      )}
      <Banner
        {...{
          bannerTitle,
          content: tipsAndTricksBannerContent,
          bannerVariant: tipsAndTricksBannerVariant,
          imageAsBackgroundAlt: tipsAndTricksBannerimageAsBackgroundAlt,
          isWithWrapper: toBoolean(withWrapper),
          withVideo,
        }}
      />
      <SignUpBanner {...{ lang }} />
    </Layout>
  );
};

export const query = graphql`
  query TipsAndTricksQuerys($tipsAndTricksId: String, $lang: String) {
    allUmbracoTipsAndTricks(
      filter: { id: { eq: $tipsAndTricksId }, langProps: { lang: { eq: $lang } } }
    ) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        videosTitle
        videosSubtitle
        tipsAndTricksVideoBanner {
          bannerTitle
          bannerVariant
          content
          imageAsBackgroundAlt
          withWrapper
          withVideo {
            autoplay
            image {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            link
            title
          }
        }
        articleListSettings {
          sectionTitle
          sectionSubtitle
          loadAllArticlesButtonText
          moreText
          moreAriaLabel
          numberOfArticles
          isLabelsActive
        }
        promoPanel {
          title
          articleTags
          numberOfBannersToShow
        }
        articlesMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
      }
    }
    allUmbracoArticles {
      nodes {
        langProps {
          lang
        }
        articleThumbnailImage {
          fallbackUrl
          fluid {
            base64
            originalImg
            srcSet
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        articleBannerImageAltText
        url
        title
        teaser
        label
        tags {
          articleTag
          id
        }
      }
    }
    allUmbracoTipsForYou(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        url
      }
    }
    allUmbracoVideoYoutube {
      nodes {
        name
        titleVideo
        videoId
        imagePoster {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
      }
    }
  }
`;

export default TipsAndTricksPage;
